import { ApplicationService, IdentityService, RouterService } from "./services";

export * from "./services";

export * from "./utils/react-hooks"

export function initialize(env: any = {}) {
  const identityService = IdentityService.getInstance();
  const router = RouterService.getInstance();
  const application = ApplicationService.getInstance();

  identityService.init({ amplify: env.auth });
  router.setPrefix(env.pathPrefix);
  router.setRoutes(env.routes);
  application.init(env);
}
